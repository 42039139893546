import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Miracl = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader
                h1Title="10 Gründe, warum miracl die Finanzierungsbranche revolutionieren wird"
                showCalc={false}
            />
            <Article>
                <h2>1. Wir sind ein junges Unternehmen</h2>
                <p>
                    Die Tatsache, dass miracl erst vor kurzem gegr&uuml;ndet wurde, mag zun&auml;chst wie ein Nachteil
                    aussehen. Tats&auml;chlich ist es aber ein Vorteil: Vor der Gr&uuml;ndung hatten unsere
                    Mitarbeiter:innen gen&uuml;gend Zeit, um die aktuellenDynamiken auf dem Immobilienmarkt
                    zu&nbsp;analysieren und die Unternehmensstruktur perfekt auf die j&uuml;ngsten Entwicklungen
                    abzustimmen. Jung ist &uuml;brigens auch unser Team &ndash; damit stellen wir sicher, dass wir dich
                    auf neuester wirtschaftswissenschaftlicher Basis beraten k&ouml;nnen und nicht nur althergebrachtes
                    Wissen weitergeben. Doch jung bedeutet keineswegs unerfahren: Unsere Mitarbeiter:innen sind absolute
                    Profis. Ihre langj&auml;hrige Erfahrung in unterschiedlichsten Bereichen des Finanzierungssektors
                    ist die Grundlage unseres Erfolgs.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>2. Uns geht es um Wissensvermittlung</h2>
                <p>
                    Die Weitergabe von Wissen ist uns bei miracl ein fundamentales Anliegen.So beraten und begleiten wir
                    dich nicht nur bei der Wahl des passenden Finanzierungsmodells f&uuml;r deine
                    Traumimmobilie.&nbsp;Mit pers&ouml;nlichen Beratungsgespr&auml;chen und dem umfangreichen
                    Informationsangebot auf unserer Website wollen wir mit dir gemeinsam einen Blick &uuml;ber den
                    Tellerrand der Immobilienbranche wagen und dir als Sparringspartner auch bei weiteren finanziellen
                    Entscheidungen zur Seite stehen.
                </p>
                <hr />

                <h2>3. Dein Erfolg ist unser Erfolg</h2>
                <p>
                    Dass wir ein junges Unternehmen sind, hat f&uuml;r dich noch einen weiteren Vorteil: Wir wollen
                    &ndash; und m&uuml;ssen &ndash; es den etablierten Gr&ouml;&szlig;en der Finanzierungsbranche
                    beweisen. Entscheidest du dich f&uuml;r miracl, dann entscheidest du dich f&uuml;r ein Team, das
                    allesdaran &nbsp;setzen&nbsp;wird, deinen Traum vom Eigenheim umzusetzen. Kompromisslos und
                    transparent.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>4. Mit uns sparst du Geld</h2>
                <p>
                    Unsere Expertise erm&ouml;glicht es uns, dir Finanzierungsm&ouml;glichkeiten aufzuzeigen, an die du
                    selbst noch nicht gedacht hast: Du denkst, du musst die gesamte Menge deiner Eigenmittel f&uuml;r
                    die Finanzierung aufwenden? Nicht unbedingt. Du kannst sie auch veranlagt lassen. Du denkst, du
                    musst deine aktuelle Immobilie in jedem Fall verkaufen? Nicht unbedingt. In einem
                    Beratungsgespr&auml;ch erarbeiten wir Alternativen zu g&auml;ngigen Strategien und erstellen auch
                    f&uuml;r dich ein detailliertes Finanzierungskonzept, das unseren Kund:innen schon h&auml;ufig
                    g&uuml;nstigere Konditionen bei unseren Partnerbanken verschafft hat, als sie durch eine
                    Direktanfrage erhalten h&auml;tten.
                </p>
                <p>
                    Wir erarbeiten f&uuml;r dich ein detailliertes Finanzierungskonzept, das dir dabei hilft,
                    g&uuml;nstigere Kondition bei deiner Bank zu bekommen und den richtigen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    zu finden.
                </p>
                <hr />

                <h2>5. Wir sparen dir Zeit</h2>
                <p>
                    Wunder geschehen nicht &uuml;ber Nacht?Miracl wird dich vom Gegenteil &uuml;berzeugen.Nach deiner
                    Anfrage ben&ouml;tigen wir nur wenige Tage, um dir einen &Uuml;berblick &uuml;ber die Angebote geben
                    zu k&ouml;nnen, die wirklich f&uuml;r dich in Frage kommen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>6. Wir sind zu 100 % unabh&auml;ngig</h2>
                <p>
                    Unser Team vergleicht die Angebote von &uuml;ber 60 Kreditinstituten, um dir tats&auml;chlich den
                    besten Deal zu verschaffen. Dabei sind wir v&ouml;llig unabh&auml;ngig: unsere
                    Finanzierungsexpert:innen sind als festangestellte Mitarbeiter:innen nicht provisionsbeteiligt.
                    Damit stellen wir sicher, dass du ein Finanzierungskonzept erh&auml;ltst, von dem in erster Linie du
                    profitierst - und nicht die Bank.
                </p>
                <hr />

                <h2>7. Wir haben eine langfristige Vision</h2>
                <p>
                    Eine Immobilie ist mehr als nur eine Wertanlage. In vielen F&auml;llen ist sie auch die Grundlage
                    f&uuml;r den sozialen Aufstieg ganzer Generationen. Als Realist:innen mit Vision stimmen wir unser
                    Angebot deshalb nicht nur auf die Wirklichkeit des Marktes ab, sondern versuchen auch, diesen
                    langfristig zu ver&auml;ndern: Unser Ziel ist eine Zukunft, in der Immobilen f&uuml;r jede:n
                    leistbar sind &ndash; auch f&uuml;r jene, die von den &sbquo;big players&lsquo; unter den
                    Kreditinstituten heute noch abgelehnt werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>8. Unsere Kunden stehen im Mittelpunkt</h2>
                <p>
                    Einer Sache kannst du dir auch auf einem so turbulenten Sektor wie dem Immobilienmarkt sicher sein:
                    Du stehst als Person im Zentrum unserer Bem&uuml;hungen &ndash; und nicht nur als Kreditnehmer:in.
                    Wirwissen aus Erfahrung, dass der Kauf eines Hauses oder einer Wohnung nicht nur deine Finanzen
                    betrifft, sondern f&uuml;r dich und dein gesamtes Umfeld eine existenzielle Ver&auml;nderung
                    bedeutet. Deshalb begleiten wir dich auf deinem Weg zur Traumimmobilie auch von Anfang bis Ende: Vom
                    ersten &Uuml;berblick &uuml;ber deine finanziellen M&ouml;glichkeiten bis zur &Uuml;bergabe der
                    Schl&uuml;ssel stehen wir dir zur Seite. Erz&auml;hl uns deine Geschichte, wir h&ouml;ren dir zu.
                </p>
                <hr />

                <h2>9. Mehr Flexibilit&auml;t durch Online-Beratung</h2>
                <p>
                    Unser Beratungsangebot ist flexibel &ndash; und passt damit nicht nur perfekt zu einem
                    Immobilienmarkt, der st&auml;ndigem Wandel unterworfen ist. Es passt auch perfekt in deinen Alltag:
                    Egal welchen beruflichen oder privaten Verpflichtungen du nachkommen musst &ndash; ob du zwischen
                    dem Fr&uuml;hst&uuml;ck und der abendlichen Yogasession kaum Zeit zum Durchatmen findest oder
                    nach&nbsp;der Arbeit noch zum Kindergarten hetzen musst, um die Kleinen abzuholen: unser
                    Online-Beratungsangebot passt auch in den turbulentesten Alltag und ist dabei doch so
                    pers&ouml;nlich, als w&uuml;rdest du uns gegen&uuml;bersitzen. Das glaubst du uns nicht? Wir sind
                    uns so sicher, dass das erste Beratungsgespr&auml;ch f&uuml;r dich v&ouml;llig kostenlos ist. Mit
                    uns wird dein Traum vom neuen Zuhause wahr, ohne dass du dein altes daf&uuml;r verlassen musst.
                </p>
                <hr />

                <h2>10. Der beste Grund: Unser Service</h2>
                <p>
                    Aber was reden wir viel von unseren Vorz&uuml;gen &ndash; &uuml;berzeuge dich doch ganz einfach
                    selbst: Vereinbare noch heute ein unverbindliches und kostenloses Beratungsgespr&auml;ch. Wenn du
                    die fachliche und menschliche Kompetenz unsere Finanzexpert:innen einmal live erlebst, besteht kein
                    Zweifel mehr: Es gibt keinen besseren Partner f&uuml;r die Realisierung deines Wohntraums als
                    miracl.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"miracl"}></BreadcrumbList>
            <ArticleStructuredData
                page={"miracl"}
                heading={"10 Gründe, warum miracl die Finanzierungsbranche revolutionieren wird"}
            />
        </Layout>
    );
};

export default Miracl;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.miracl", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
